import React from "react";

import PopupLogo from "../../assets/images/popup-logo.png";
import PopupLogoMobile from "../../assets/images/popup-logo-mobile.svg";

import GitexLogo from "../../assets/images/gitex-logo-white.svg";
import "../../assets/css/master.css";
import { Dialog, useMediaQuery } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CloseIcon from "@mui/icons-material/Close";

const eventURL = "https://calendly.com/pivott/gitex-2024?month=2024-10";

const EventPopUp = ({ isOpen, setIsOpen }) => {
  let isXs = false;
  let isSm = false;
  let isMd = false;
  let isLarge = false;

  isXs = useMediaQuery("(max-width:574px)");
  isSm = useMediaQuery("(max-width:991px)");
  isMd = useMediaQuery("(max-width:1600px)");
  isLarge = useMediaQuery("(min-width:600px)");

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        "& .MuiPaper-root": {
          //   minWidth: ["", "", "70vw", "60vw", "50vw"],
          maxWidth: "1000px",
          borderRadius: "24px",
          overflow: "hidden",
        },
        ".scroll-container": {
          overflowY: "auto",
          maxHeight: "calc(100vh - 200px)",
        },
        ".scroll-container::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <div className="row position-relative">
        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6 xs-12">
          <div className="popup-logo-sec">
            {isXs ? (
              <img src={PopupLogoMobile} alt="popup logo" style={{maxHeight : isXs && "300px", width : "100%"}} />
            ) : (
              <img src={PopupLogo} alt="img" style={{height : "100%"}}  />
            )}
          </div>
        </div>
        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-6 xs-12">
          <div
            className="popup-info-sec"
            style={{
              padding: isXs && "20px 10px",
              gap : isXs && "16px"
            }}
             
          >
            <CloseIcon className="closeIcon" onClick={() => setIsOpen(false)} />
            <h1 className="fw-semibold">
              Meet Us at <span>GITEX Global</span> 2024
            </h1>

            <div className="popup-date-section">
              <div class="event-date">14-18 OCT 2024</div>
              <div class="event-location">DUBAI WORLD TRADE CENTRE</div>
            </div>

            <button
              className="ps-btn-gitex"
              type="button"
              onClick={() => window.open(`${eventURL}`)}
            >
              Schedule a meeting
              <span>
                <ArrowRightAltIcon style={{ display: "inline" }} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EventPopUp;
