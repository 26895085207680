import {
  GOOGLE_LOGIN,
  GOOGLE_URI,
  LOGIN,
  MICROSOFT_LOGIN,
  MICROSOFT_URI,
} from "../constants/routes";
import { buildUrl } from "../utils";
import { FetchApi } from "./fetchApi";

export const LoginAPI = {
  create: function (data) {
    return FetchApi({
      path: LOGIN,
      type: "POST",
      data: JSON.stringify(data),
      isLoader: false,
    });
  },
};

const socialLoginRedirectUrl = () => `${buildUrl()}/auth-callback/`

// google apis for SSO
export const GoogleLoginAPI = {
  getGoogleLogin: function (code, device_id, company = null, coupon = null) {
    let redirect_url = socialLoginRedirectUrl();
    let url = `${GOOGLE_LOGIN}?code=${code}&redirect_url=${redirect_url}&device_id=${device_id}`;
    if (company) {
      url += `&company_name=${company}`
    }
    if (coupon) {
      url += `&referral_code=${coupon}`
      }
    return FetchApi({ path: url, type: "GET" });
  },

  getGoogleUri: function () {
    let redirect_url = socialLoginRedirectUrl();

    return FetchApi({
      path: `${GOOGLE_URI}?redirect_url=${redirect_url}&url_type=sign_in`,
      type: "GET"
    });
  },
};

// microsoft apis for SSO
export const MicrosoftLoginAPI = {

  getMicrosoftLogin: function (code, device_id, company = null) {
    let redirect_url = socialLoginRedirectUrl();

    let url = `${MICROSOFT_LOGIN}?code=${code}&redirect_url=${redirect_url}&device_id=${device_id}`
    if (company) {
      url += `&company_name=${company}`
    }
    return FetchApi({ path: url, type: "GET" });
  },

  getMicrosoftUri: function () {
    let redirect_url = socialLoginRedirectUrl();

    return FetchApi({
      path: `${MICROSOFT_URI}?redirect_url=${redirect_url}&url_type=sign_in`,
      type: "GET"
    });
  },
};